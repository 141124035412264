<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Paquete - Nuevo</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">

              <b-tabs content-class="mt-3">
                <b-tab title="Español" active>
                  <b-row>
                    <b-col md="6">
                      <b-form-group label="Categoria:">
                        <b-form-select  v-model="mpackage.id_package_category" :options="package_category_es" ></b-form-select>
                        <small v-if="errors.id_package_category"  class="form-text text-danger" >Seleccione una categoria</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Codigo:">
                        <b-form-input  v-model="mpackage.code" ></b-form-input>
                        <small v-if="errors.code"  class="form-text text-danger" >Ingrese un codigo</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group label="Titulo:">
                        <b-form-input  v-model="mpackage.title_es" ></b-form-input>
                        <small v-if="errors.title_es"  class="form-text text-danger" >Ingrese un titulo</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group label="Descripción:">
                        <b-form-input  v-model="mpackage.description_es"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Duración:">
                        <b-form-input  v-model="mpackage.duration_es"></b-form-input>
                        <small v-if="errors.duration_es"  class="form-text text-danger" >Ingrese una duración</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Precio:">
                        <b-form-input class="text-right" type="number" step="any" v-model="mpackage.price_es"></b-form-input>
                        <small v-if="errors.price_es"  class="form-text text-danger" >Ingrese un precio</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group label="Video:">
                        <b-form-input v-model="mpackage.video"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Favorito:">
                        <b-form-select  v-model="mpackage.favorite" :options="favorite"></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Estado:">
                        <b-form-select  v-model="mpackage.state" :options="state"></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group label="Especificación:">
                        <tinymce :height="800" v-model="mpackage.specification_es" id='specification_es'></tinymce>
                        <small v-if="errors.specification_es"  class="form-text text-danger" >Ingrese un especificación</small>
                      </b-form-group>
                    </b-col>
                    
                    
                  </b-row>
                </b-tab>
                <b-tab title="Ingles">
                  <b-row>
                    <b-col md="6">
                      <b-form-group label="Category:">
                        <b-form-select  v-model="mpackage.id_package_category" :options="package_category_en" ></b-form-select>
                        <small v-if="errors.id_package_category"  class="form-text text-danger" >Seleccione una categoria</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Code:">
                        <b-form-input  v-model="mpackage.code" ></b-form-input>
                        <small v-if="errors.code"  class="form-text text-danger" >Ingrese un codigo</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group label="Title:">
                        <b-form-input  v-model="mpackage.title_en" ></b-form-input>
                        <small v-if="errors.title_en"  class="form-text text-danger" >Ingrese un titulo</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group label="Description:">
                        <b-form-input  v-model="mpackage.description_en"></b-form-input>
                        <small v-if="errors.description_en"  class="form-text text-danger" >Ingrese un descripción</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Duration:">
                        <b-form-input  v-model="mpackage.duration_en"></b-form-input>
                        <small v-if="errors.duration_en"  class="form-text text-danger" >Ingrese un duracion</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Price:">
                        <b-form-input class="text-right" type="number" step="any" v-model="mpackage.price_en"></b-form-input>
                        <small v-if="errors.price_en"  class="form-text text-danger" >Ingrese un precio</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group label="Video:">
                        <b-form-input v-model="mpackage.video"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Favorito:">
                        <b-form-select  v-model="mpackage.favorite" :options="favorite"></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Estado:">
                        <b-form-select  v-model="mpackage.state" :options="state"></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group label="Specification:">
                        <tinymce :height="800" v-model="mpackage.specification_en" id='specification_en'></tinymce>
                        <small v-if="errors.specification_en"  class="form-text text-danger" >Ingrese un especificación</small>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>
                <b-tab title="Imagenes">
                  <b-row>
                    <b-col md="6">
                      <b-form-group>
                        <label for="">Imagen Principal: <small>Tamaño recomendado (1000x600)px</small></label>
                        <b-form-file
                          accept="image/png, image/jpeg"
                          v-model="file"
                          :state="Boolean(file)"
                          @change="onFileChange"
                          @input="pickFile"
                          ref="fileInput"
                          placeholder="Seleccione una imagen"
                          drop-placeholder="Arrastre la imagen aqui"
                        ></b-form-file>
                        <small v-if="errors.image"  class="form-text text-danger" >Seleccione una imagen</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group>
                        <label for="">Mas Imagenes: <small>Tamaño recomendado (1000x600)px</small></label>
                        <b-form-file
                          accept="image/png, image/jpeg"
                          v-model="files"
                          ref="uploadfiles"
                          :state="Boolean(files)"
                          @change="onFilesChange"
                          multiple
                          placeholder="Seleccione las imagenes"
                          drop-placeholder="Arrastre las imagenes aqui"
                        ></b-form-file>
                        <small v-if="errors.image"  class="form-text text-danger" >Seleccione una imagen</small>
                      </b-form-group>
                    </b-col>

                    <!-- <b-col md="3"></b-col>
                    <b-col md="3"></b-col>
                    <b-col md="6" class="text-center">
                      <img class="img-fluid text-center border" style="max-height:500px" :src="previewImage" alt="">
                    </b-col>
                    <b-col md="3"></b-col> -->


                    
                  </b-row>
                </b-tab>
              </b-tabs>

              <b-row>
                <b-col md="5"></b-col>
                <b-col md="2">
                  <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                </b-col>
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>



    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";

// components
import LoadingComponent from './../pages/Loading'
export default {
  name: "UsuarioAdd",
  components:{
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'Package',
      role: 2,
      mpackage: {
        id_package: '',
        id_package_category: '',
        code: '',
        title_es: '',
        description_es: '',
        duration_es: '',
        price_es: '',
        specification_es: '',
        title_en: '',
        description_en: '',
        duration_en: '',
        price_en: '',
        specification_en: '',
        image: '',
        images: '',
        video: '',
        state: 1,
        favorite: 0,
      },
      file:null,
      files:null,
      state:[
        {value:'1',text:'Activo'},
        {value:'0',text:'Inactivo'},
      ],
      favorite:[
        {value:'1',text:'SI'},
        {value:'0',text:'NO'},
      ],

      package_category_en:[],
      package_category_es:[],
      previewImage: null,
      errors: {
        id_package_category: false,
        code: false,
        

        title_es: false,
        description_es: false,
        specification_es: false,
        description_es: false,
        duration_es: false,
        price_es: false,

        title_en: false,
        description_en: false,
        specification_en: false,
        description_en: false,
        duration_en: false,
        price_en: false,

        image: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ListPackageCategory();
  },
  methods: {
    AddPackage,
    Validate,
    onFileChange,
    onFilesChange,
    ListPackageCategory,
    pickFile () {
      let input = this.$refs.fileInput
      let file = input.files
      if (file && file[0]) {
        let reader = new FileReader
        reader.onload = e => {
          this.previewImage = e.target.result
        }
        reader.readAsDataURL(file[0])
        this.$emit('input', file[0])
      }
    }
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};

function onFileChange(e) {
  this.mpackage.image = e.target.files[0];
}

function onFilesChange(e) {
  this.mpackage.images = e.target.files;
}

function ListPackageCategory() {
  let me = this;
  let url = me.url_base + "packages-category/select-active";
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      me.package_category_es.push({ value:'',text:'-- Seleccione una categoria --' });
      me.package_category_en.push({ value:'',text:'-- Seleccione una categoria --' });
      if (response.data.status == 200) {
          for (let index = 0; index < response.data.result.length; index++) {
            const element = response.data.result[index];
            me.package_category_es.push({value:element.id_package_category, text:element.title_es});
            me.package_category_en.push({value:element.id_package_category, text:element.title_en});
          }
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function AddPackage() {
  let me = this;
  let url = me.url_base + "package/add";
  let data = new FormData();
  data.append("id_package", me.mpackage.id_package);
  data.append("id_package_category", me.mpackage.id_package_category);
  data.append("code", me.mpackage.code);
  data.append("title_es", me.mpackage.title_es);
  data.append("description_es", me.mpackage.description_es);
  data.append("duration_es", me.mpackage.duration_es);
  data.append("price_es", me.mpackage.price_es);
  data.append("specification_es", me.mpackage.specification_es);

  data.append("title_en", me.mpackage.title_en);
  data.append("description_en", me.mpackage.description_en);
  data.append("duration_en", me.mpackage.duration_en);
  data.append("price_en", me.mpackage.price_en);
  data.append("specification_en", me.mpackage.specification_en);
  data.append("image", me.mpackage.image);
  // data.append("images[]", me.mpackage.images);
  data.append("video", me.mpackage.video);
  data.append("state", me.mpackage.state);
  data.append("favorite", me.mpackage.favorite);

  var files = this.$refs.uploadfiles.files;
  var totalfiles = this.$refs.uploadfiles.files.length;
  for (var index = 0; index < totalfiles; index++) {
    data.append("images[]", files[index]);
  }
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 201) {
          me.mpackage.id_package_category = '';
          me.mpackage.code = '';
          me.mpackage.title_es = '';
          me.mpackage.description_es = '';
          me.mpackage.duration_es = '';
          me.mpackage.price_es = '';
          me.mpackage.specification_es = '';
          me.mpackage.title_en = '';
          me.mpackage.description_en = '';
          me.mpackage.duration_en = '';
          me.mpackage.price_en = '';
          me.mpackage.specification_en = '';
          me.mpackage.image = '';
          me.mpackage.video = '';
          me.mpackage.state = 1;

          me.file = null;
          me.files = null;
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function Validate() {

  this.errors.id_package_category = this.mpackage.id_package_category.length == 0 ? true : false;
  this.errors.code = this.mpackage.code.length == 0 ? true : false;

  this.errors.title_es = this.mpackage.title_es.length == 0 ? true : false;
  this.errors.description_es = this.mpackage.description_es.length == 0 ? true : false;
  this.errors.duration_es = this.mpackage.duration_es.length == 0 ? true : false;
  this.errors.price_es = this.mpackage.price_es.length == 0 ? true : false;
  this.errors.specification_es = this.mpackage.specification_es.length == 0 ? true : false;

  this.errors.title_en = this.mpackage.title_en.length == 0 ? true : false;
  this.errors.description_en = this.mpackage.description_en.length == 0 ? true : false;
  this.errors.duration_en = this.mpackage.duration_en.length == 0 ? true : false;
  this.errors.price_en = this.mpackage.price_en.length == 0 ? true : false;
  this.errors.specification_en = this.mpackage.specification_en.length == 0 ? true : false;

  this.errors.image = this.mpackage.image.length == 0 ? true : false;
  this.errors.state = this.mpackage.state.length == 0 ? true : false;

  if (this.errors.id_package_category) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.code) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }

  if (this.errors.title_es) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios en español esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.description_es) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios en español esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }  
  if (this.errors.duration_es) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios en español esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }  
  if (this.errors.price_es) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios en español esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }  
  if (this.errors.specification_es) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios en español esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }  

  if (this.errors.title_en) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios en ingles esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }  
  if (this.errors.description_en) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios en ingles esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }  
  if (this.errors.duration_en) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios en ingles esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }  
  if (this.errors.price_en) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios en ingles esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }  
  if (this.errors.specification_en) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios en ingles esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }  

  if (this.errors.image) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }  
  if (this.errors.state) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }  

  let me = this;
  Swal.fire({
    title: "Esta seguro de registrar el paquete ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.AddPackage();
    }
  });
}
</script>
